import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { JwtInterceptor, AuthGuard, SnackbarService } from 'src/app/core/helpers';
import { MaterialModule } from 'src/app/material.module';

import { AccountService } from './services';

@NgModule({
  declarations: [],
  imports: [
    MaterialModule
  ],
  providers: [
    SnackbarService,
    AccountService,
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ]
})
export class CoreModule { }
