import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { WINDOW, HISTORY } from "@ng-web-apis/common";

import { AuthenticationService, AccountService } from 'src/app/core/services';
import { UserSession } from 'src/app/shared/models';
import { SnackbarService } from '../core/helpers';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';

@Component({
  selector: 'app-auto-login',
  templateUrl: './auto-login.component.html',
  styleUrls: ['./auto-login.component.scss']
})
export class AutoLoginComponent implements OnInit {

  queryParams: QueryParams;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private accountService: AccountService,
    private router: Router,
    private snackbarService: SnackbarService,
    @Inject(WINDOW) readonly windowRef: Window,
    @Inject(HISTORY) readonly historyRef: History,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.queryParams = {
        token: params.token,
        backUrl: params.backUrl,
        brandId: +params.brandId,
        merchantId: params.merchantId,
        valid: +params.valid,
        message: params.message,
        referenceNumber: params.referenceNumber,
        transactionUuid: params.transactionUuid,
        accessCode: params.accessCode,
        payeeCode: params.payeeCode,
        paymentModel: params.paymentModel,
        supplierId: params.supplierId,
        merchantKeyId: params.merchantKeyId,
        type: params.type,
        userName: params.userName
      };
      if(this.queryParams.valid === 0) {
        this.showErrorDialog(this.queryParams.message || 'Not a valid request');
      }
      else if (this.queryParams.valid === 1 && this.isCardParams) {
        this.updateToken();
        this.validateToken();
      }
      else {
        this.showErrorDialog('Not a valid request');
      }
    });
  }

  get isCardParams(): boolean {
    return (!!this.queryParams.token && !!this.queryParams.brandId) && (!!this.queryParams.backUrl && !!this.queryParams.merchantId);
  }

  validateToken() {
    this.accountService.getProfileInfo().subscribe((response) => {
      this.updateUserSessionData();
      this.navigateToCardAdd();
    }, (error) => {
      this.showErrorDialog('Invalid Token!');
    });
  }

  updateUserSessionData() {
    const sessionDetails: UserSession = {
      token: this.queryParams.token,
      brandId: this.queryParams.brandId,
      url: this.queryParams.backUrl,
      merchantId: this.queryParams.merchantId,
      referenceNumber: this.queryParams.referenceNumber,
      transactionUuid: this.queryParams.transactionUuid,
      accessCode: this.queryParams.accessCode,
      payeeCode: this.queryParams.payeeCode,
      paymentModel: this.queryParams.paymentModel,
      supplierId: this.queryParams.supplierId,
      merchantKeyId: this.queryParams.merchantKeyId,
      type: this.queryParams.type,
      userName: this.queryParams.userName
    };
    this.authService.updateCurrentUser(sessionDetails);
  }

  updateToken() {
    const sessionDetails: UserSession = {
      token: this.queryParams.token
    };
    this.authService.updateCurrentUser(sessionDetails);
  }

  navigateToCardAdd() {
    this.router.navigateByUrl('/common/new-card');
  }

  showErrorDialog(message: string) {
    let dialogRef = this.dialog.open(ErrorDialogComponent, {
      data: { message: message },
      disableClose: true,
      width: '420px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (this.queryParams.backUrl) {
        this.windowRef.location.href = this.queryParams.backUrl;
      }
      else {
        this.historyRef.back();
      }
    });
  }
  
}

interface QueryParams {
  token?: string;
  brandId?: number;
  backUrl?: string;
  merchantId?: string;
  valid?: number;
  message?: string;
  referenceNumber?: string;
  transactionUuid?: string;
  accessCode?: string;
  payeeCode?: string;
  paymentModel?: string;
  supplierId?: number;
  merchantKeyId?: string;
  userName?: string;
  type?: string;
}
