import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class UserService {

  constructor(private http: HttpClient) { }

  getBuyerProfile() {
    return this.http.get<any>(`${environment.apiUrl + 'users/profile'}`);
  }
}
