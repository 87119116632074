<div>
    <div class="d-flex flex-column justify-content-center mb-4">
        <div class="mb-3 text-center">
            <mat-icon class="text-warning fs-3-5 my-2">error_outline</mat-icon>
        </div>
        <div>
            <p class="text-center">{{data.message}}</p>
        </div>
    </div>
    <div class="d-flex justify-content-center">
        <button mat-raised-button type="button" (click)="closeDialog()">Go back</button>
    </div>
</div>